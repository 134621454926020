import React, { useEffect, useState } from 'react';
import { Row, Col, Popover, Button, Menu, Drawer, Avatar } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import ChileFlag from 'cmt-ui-kit/src/images/ChileFlag.svg';
import PeruFlag from 'cmt-ui-kit/src/images/PeruFlag.svg';
import { Link } from 'gatsby';
import Info from './Info';

const { SubMenu } = Menu;

const MobilePublicLayout = ({
  t,
  data,
  handlers,
  userName,
  HasAccess,
  isLoggedIn,
  countryCode,
  handleExamsOrder }) => {
  const [visible, setVisible] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    name: null,
    flag: null,
    url: null,
  });

  useEffect(() => {
    if (countryCode === 'CL') {
      setCurrentCountry(() => ({
        name: 'Chile',
        flag: ChileFlag,
        url: 'https://www.cmtsalud.cl/',
      }));
    } else {
      setCurrentCountry(() => ({
        name: 'Perú',
        flag: PeruFlag,
        url: 'https://www.cmtsalud.pe/',
      }));
    }
  }, [countryCode]);

  const countryPopoverContent = () => {
    const handleRedirect = (selectedCountryCode) => {
      if (countryCode !== selectedCountryCode) {
        window.location.replace(`https://www.cmtsalud.${selectedCountryCode.toLowerCase()}/`);
      }
    };

    return (
      <Row gutter={[0, 10]}>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('CL')}
          >
            <img src={ChileFlag} alt={'Bandera Chile'} width='35' />
            &nbsp;Chile
          </Button>
        </Col>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('PE')}
          >
            <img src={PeruFlag} alt={'Bandera Perú'} width='35' />
            &nbsp;Perú
          </Button>
        </Col>
      </Row>
    );
  };

  const handleDrawerVisibility = () => {
    setVisible(!visible);
  };


  return (
    <Row justify='end' align="middle" className="menu-mobile" gutter={23}>
      <Col className='country-wrapper'>
        {
          currentCountry.name &&
          <Popover
            trigger='click'
            placement='bottom'
            overlayClassName='country-home-popover'
            title='Selecciona país'
            content={countryPopoverContent}
          >
            <Button className='cmt-button disabled' type='primary'>
              <img
                src={currentCountry.flag}
                alt={currentCountry.name + 'bandera'}
                width='35'
              />
              <strong>&nbsp;{currentCountry.name}</strong>
              <span className='icon-system-chevron-down ml-5' />
            </Button>
          </Popover>
        }
      </Col>
      <Col className='menu-wrapper'>
        <Button
          className="menu-mobile-button"
          shape="circle"
          onClick={handleDrawerVisibility}
          icon={!visible ? <MenuOutlined /> : <span className='icon-system-close' />}
        />
      </Col>

      <Drawer
        open={visible}
        onClose={handleDrawerVisibility}
        placement="right"
        closable={false}
        className="public-layout-drawer"
      >
        <Row className='drawer-container'>
          <Col xs={24} className='menu-mobile-wrapper'>
            <Menu mode="inline">
              <Menu.Item key="1" className='user-wrapper'>
                <Link
                  to={
                    (isLoggedIn() && userName) ? '/app/' : '/app/login/'
                  }
                >
                  {
                    (isLoggedIn() && userName) ?
                      <Row>
                        <Col className="mr-10">
                          <p className="mb-0">{userName}</p>
                        </Col>
                        <Col>
                          <Avatar>{userName.charAt()}</Avatar>
                        </Col>
                      </Row>
                      :
                      <Row align="middle">
                        <Col>
                          <Button
                            className="cmt-button primary"
                            size="large"
                            type="primary"
                          >
                            Acceso clientes
                          </Button>
                        </Col>
                      </Row>
                  }
                </Link>
              </Menu.Item>
              <SubMenu key="sub1" title="Servicios" className='services-main-submenu'>
                <p className='mt-10 mb-20'>Servicios de Salud Laboral</p>
                <SubMenu className='submenu-services-list' key='sub1g1' title={<p className='mb-0 submenu-title'>{t('navbar.ourServicesTitle')}</p>}>
                  <Menu.Item className='services-list'>
                    <Link
                      to={countryCode === 'CL' ? `/examenes-preocupacionales-ocupacionales/` : `/examenes-medicos-ocupacionales/`}
                    >
                      <p className='bold mb-0'>{t('navbar.seeAllBatteries')}</p>
                    </Link>
                  </Menu.Item>
                  {
                    handleExamsOrder(data, 'device-mobile')
                  }
                  {
                    data.wpgraphql.otherbatteries.nodes.filter(exam => exam.fields.country === countryCode).map((exam) => {
                      return (
                        <Menu.Item key={exam.slug} className='services-list'>
                          <Link to={`/examenes/${exam.slug}/`}>
                            <p className='mb-0'>{exam.fields.title}</p>
                          </Link>
                        </Menu.Item>
                      )
                    })
                  }
                </SubMenu>
                {
                  countryCode === 'CL' &&
                  <Menu.ItemGroup
                    key="g2"
                    title={
                      <Link
                        to='/unidades-de-salud-en-empresas/'
                        activeClassName="active"
                      >
                        Unidades de Salud en Empresas
                      </Link>
                    }
                  />
                }
                {
                  countryCode === 'CL' &&
                  <Menu.ItemGroup
                    key="g3"
                    title={
                      <Link
                        to='/operativos-de-salud-en-terreno/'
                        activeClassName="active"
                      >
                        Operativos de Salud en Terreno
                      </Link>
                    }
                  />
                }
              </SubMenu>
              <Menu.Item key="3">
                <Link
                  to={`/${t('navbar.branchOffices').toLocaleLowerCase()}/`}
                  activeClassName="active"
                >
                  {t('navbar.branchOffices')}
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                {
                  isLoggedIn()
                    ? <HasAccess
                      permissions="can_reserve"
                    >
                      <Link
                        to="/app/reservar/"
                        activeClassName="active"
                      >
                        <strong>{t("navbar.scheduleTime")}</strong>
                      </Link>
                    </HasAccess>
                    : <Link
                      to="/app/reservar/"
                      activeClassName="active"
                    >
                      <strong>{t("navbar.scheduleTime")}</strong>
                    </Link>
                }
              </Menu.Item>
              <Menu.Item key="5">
                {
                  isLoggedIn()
                    ? <HasAccess
                      permissions="can_download_results"
                    >
                      <Link
                        to="/app/reservas?from=login"
                        activeClassName="active"
                      >
                        <strong>Descarga de resultados</strong>
                      </Link>
                    </HasAccess>
                    : <Link
                      to="/app/login?redirect=/app/reservas?from=login"
                      activeClassName="active"
                    >
                      <strong>Descarga de resultados</strong>
                    </Link>
                }

              </Menu.Item>
              <Menu.Item key="6">
                <Link
                  to="/app/checker/"
                  activeClassName="active"
                >
                  <strong>Verificación de exámenes</strong>
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
          <Col xs={24} className='info-mobile-wrapper'>
            <Info device='device-mobile' data={data} splitPhone={handlers.splitPhone} countryCode={countryCode} />
          </Col>
        </Row>
      </Drawer>
    </Row>
  );
};

export default MobilePublicLayout;