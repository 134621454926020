import React from "react";
import { Row, Col } from "antd";
import { Link } from "gatsby";
import { handlers } from "../../../helpers/handlers";

const ContactCards = ({ settings, t }) => {
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const urlPath = countryCode === 'CL' ? '/sucursales/' : '/sede/';

  const cardsContent = [
    {
      icon: 'icon-callcenter',
      title: 'Central telefónica',
      subTitle: <p className="text-center" dangerouslySetInnerHTML={{ __html: t('contact.openingHours')}} />,
      action: <a href={`tel:${settings.nodes[0].fields.centralPhone}`}>{handlers.splitPhone(settings.nodes[0].fields.centralPhone)}</a>
    },
    {
      icon: 'icon-envelope',
      title: 'Escríbenos',
      subTitle: <p className="text-center">directamente <br /> desde tu email a</p>,
      action: <Row justify="center" gutter={[0, 8]} className="email-wrapper" align="middle">
        <Col>
          <a href={`mailto:${settings.nodes[0].fields.centralEmail}`}>{settings.nodes[0].fields.centralEmail}</a>
        </Col>
        {
          settings.nodes[0].fields.noticeEmail &&
            <Col>
              <a href={`mailto:${settings.nodes[0].fields.noticeEmail}`}>{settings.nodes[0].fields.noticeEmail}</a>
            </Col>
        }
      </Row>
    },
    {
      icon: 'icon-placeholder-new',
      title: t('contact.branchOfficeTitle'),
      subTitle: <p className="text-center" dangerouslySetInnerHTML={{ __html: t('contact.location')}} />,
      action: <Link to={urlPath}>Ver {t('contact.branchOfficeTitle').toLowerCase()}</Link>
    },
    {
      icon: 'icon-question-rounded',
      title: 'Ayuda',
      subTitle: <p className="text-center">Encuentra las preguntas <br/> más frecuentes</p>,
      action: <a href="https://cmtsaludsupport.zendesk.com/hc/es-419/sections/14440390551323-Preguntas-Frecuentes" target="_blank" rel="noopener, noreferrer">Ver más</a>
    }
  ];


  return (
    <Row
      justify='center'
      className="contact-cards-wrapper mt-lg-130 mt-md-70 mt-sm-70 mb-lg-130 mb-md-30 mb-sm-30 mb-xs-30"
      align="stretch"
    >
      <Col lg={24} xs={24}>
        <h4 className="text-center mb-lg-40 mb-md-30 mb-sm-30 mb-xs-30">
          Nuestros canales de <italic>atención directos</italic>
        </h4>
      </Col>
      <Col lg={24} xs={24}>
        <Row justify={countryCode === 'CL' ? 'start' : 'center'} gutter={[23, 40]}>
        {
          cardsContent.length && cardsContent.map((card, index) => {
            if (countryCode === 'PE' && index !== 3) {
              return (
                <Col lg={6} xs={24} key={index}>
                  <Row justify='center' className='card-container'>
                    <Col className="icon-wrapper">
                      <span className={card.icon} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <h5 className="text-center">{card.title}</h5>
                    </Col>
                    <Col lg={24} xs={24}>
                      {card.subTitle}
                    </Col>
                    <Col>
                      {card.action}
                    </Col>
                  </Row>
                </Col>
              )
            }
            if (countryCode === 'CL') {
              return (
                <Col lg={6} xs={24} key={index}>
                  <Row justify='center' className='card-container'>
                    <Col className="icon-wrapper">
                      <span className={card.icon} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <h5 className="text-center">{card.title}</h5>
                    </Col>
                    <Col lg={24} xs={24}>
                      {card.subTitle}
                    </Col>
                    <Col>
                      {card.action}
                    </Col>
                  </Row>
                </Col>
              )
            }
            
            return null;
          })
        }
        </Row>
      </Col>
    </Row>
  );
};

export default ContactCards;